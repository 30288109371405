// Grid system
.main {
  @include make-sm-column($main-sm-columns);
  .sidebar-primary & {
    @include make-sm-column($main-sm-columns - $sidebar-sm-columns);
  }
}
.sidebar {
  @include make-sm-column($sidebar-sm-columns);
}

.container {
    max-width: 940px;
    padding-left: 0;
    padding-right: 0;
}
@media only screen and (max-width: 768px) {
    .container {
        padding-left: 15px;
        padding-right: 15px;
    }
}
