body {
    max-width: 100%!important;
    overflow-x: hidden!important;
    font-family: 'Fira Sans', sans-serif;
    color: $font-primary;
    background-color: $bg-boxen;
    header .container {
        background-color: #fff;
        box-shadow: 0 -10px 15px 5px rgba(182,182,182,.18);
    }
    .wrap.container {
        background-color: #fff;
        box-shadow: 0 10px 15px 5px rgba(182,182,182,.18);
    }
    footer .container {
        box-shadow: 0 10px 15px 5px rgba(182,182,182,.18);
    }
    p {
        margin-bottom: 20px;
        font-weight: 300;
        font-size: 16px;
        line-height: 1.262;
    }
    main {
        padding-top: 35px;
        padding-bottom: 50px;
    }
    .pad-bot {
        padding-bottom: 25px;
    }
    .page-header {
        margin-top: 0;
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: none;
    }
    @media only screen and (min-width: 768px) {
        .page-content {
            padding-left: 35px;
        }
    }
    h1 {
        margin-top: 0;
        margin-bottom: 35px;
        font-family: 'Fira Sans', sans-serif;
        font-size: 24px;
        font-style: italic;
        font-weight: 300;
        line-height: 1.125;
        box-shadow: 0 1px 1px rgba(0,0,0,.05);
    }
    span.w220 {
        display: inline-block;
        min-width: 220px;
    }
    @media only screen and (max-width: 767px) {
        .wrap.container {
            main {
                padding-bottom: 10px;
            }
            aside.sidebar {
                padding-bottom: 0;
                padding-left: 0;
                padding-right: 0;
                .box {
                    padding-left: 15px;
                    padding-right: 15px;
                    margin-left: 0;
                    margin-right: 0;
                }
            }
        }
    }
}

.fl {
    display: block;
    float: left;
}
.fr {
    display: block;
    float: right;
}
.clear-all {
    display: block;
    clear: both;
}
