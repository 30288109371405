// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    3;

$brand-blue:            #009EE3;
//$brand-orange:          #E84605;
//$brand-orange:          #C5065C;
$brand-orange:          #E50051;

$bg-footer:             #7C7C7F;
$bg-boxen:              #F4F4F4;

$font-primary:          #818281;

// Colors
$green-primary:         #27ae60;
$green-light:           #d1f4a4;
