header {
    .container {
        height: 258px;
        //background-image: url("/wp-content/themes/linicus/dist/images/header_bg.jpg");
        //background-repeat: no-repeat;
        //background-position: top left;
        position: relative;
        .header-bg {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            overflow: hidden;
            max-width: 100%!important;
            background-repeat: no-repeat;
            background-position: center center;
            img {
                display: block;
                height: 258px;
                width: 940px;
            }
            //background-color: #0000cc;
            .soliloquy-outer-container {
                .soliloquy-container {
                    .soliloquy-wrapper {
                        .soliloquy-viewport {
                            ul.soliloquy-slider li.soliloquy-item img.soliloquy-image[style] {
                                display: inline-block;
                                margin: 0 auto !important;
                                width: 100% !important;
                                overflow: hidden;
                                text-align: center;
                            }
                        }
                        @media only screen and (max-width: 767px) {
                            .soliloquy-viewport[style] {
                                //margin-left: -470px !important;
                                max-width: 940px !important;
                                width: 940px !important;
                            }
                            .soliloquy-controls {
                                display: none!important;
                            }
                        }
                    }
                    .soliloquy-wrapper[style]{
                        overflow: hidden!important;
                        position: relative!important;
                    }
                }
            }
        }
        .main-menu {
            nav.navbar {
                border: none;
                background-color: transparent;
                margin-bottom: 0;
                //min-height: 50;
                .container-fluid {
                    padding-left: 0;
                    padding-right: 0;
                    #main-menu {
                        padding-left: 0;
                        padding-right: 0;
                        ul {
                            background-color: $brand-blue;
                            li {
                                a {
                                    margin-top: 10px;
                                    margin-bottom: 10px;
                                    padding: 0 20px;
                                    background-color: transparent;
                                    border-right: 1px solid rgb(255, 255, 254);
                                    font-size: 14px;
                                    color: rgb(255, 255, 254);
                                    line-height: 1.2;
                                    .caret {
                                        display: none;
                                    }
                                }
                            }
                            li:last-of-type a {
                                border-right: none;
                            }
                            li.active, li:hover, li.current-menu-ancestor {
                                background-color: $brand-orange;
                            }
                            @media only screen and (min-width: 767px) {
                                li.dropdown:hover  .dropdown-menu {
                                    display: block;
                                }
                                ul.dropdown-menu {
                                    border: none;
                                    border-radius: 0;
                                    background-color: rgba(255,255,255,0.9);
                                    li.active {
                                        background-color: transparent;
                                        a {
                                            color: $brand-orange;
                                        }
                                    }
                                    li:hover {
                                        background-color: transparent;
                                        a {
                                            color: $brand-orange;
                                        }
                                    }
                                    li {
                                        a {
                                            color: $font-primary;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        @media only screen and (max-width: 767px) {
            .main-menu {
                nav.navbar {
                    .container-fluid {
                        position: relative;
                        //overflow-x: hidden;
                        .navbar-header {
                            background-color: $brand-blue;
                            button {
                                background-color: $brand-orange;
                                .icon-bar {
                                    background-color: #fff;
                                }
                            }
                            .company[style]{
                                display: inline-block!important;
                                height: 50px;
                                padding-left: 15px;
                                font-size: 24px;
                                line-height: 50px;
                                color: #fff;
                                a {
                                    color: #fff;
                                }
                            }
                            @media only screen and (max-width: 400px) {
                                .company[style] {
                                    font-size: 18px;
                                }
                            }
                            @media only screen and (max-width: 320px) {
                                .company[style] {
                                    font-size: 14px;
                                }
                            }
                        }
                        #main-menu {
                            position: absolute!important;
                            top: 50px;
                            width: 150%;
                            z-index: 9999;
                            border-top: 1px solid $brand-orange;
                            overflow-x: hidden;
                            #menu-hauptmenue {
                                margin-top: 0;
                                margin-bottom: 0;
                                padding-top: 10px;
                                padding-bottom: 10px;
                                padding-left: 15px;
                            }
                        }
                    }
                }
            }
        }
    }
    .brand {
        a {
            img {
                display: block;
                margin-top: 60px;
                margin-bottom: 10px;
                width: 100%;
                max-width: 584px;
                height: auto;
            }
        }
    }
    @media only screen and (max-width: 767px) {
        .brand {
            a {
                img.logo-full {
                    display: none;
                }
                img.logo-mobile {
                    display: block!important;
                    max-width: 120px;
                }
            }
        }
    }
}