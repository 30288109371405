.sidebar {
    padding-top: 35px;
    padding-bottom: 50px;
    .box {
        margin-right: 40px;
        margin-left: -15px;
        margin-bottom: 10px;
        font-size: 12px;
        line-height: 1.263;
        padding: 2px 10px 10px;
        background-color: $bg-boxen;
        hr {
            margin-top: 8px;
            margin-bottom: 8px;
            border-top: 1px solid $font-primary;
        }
        a, a:hover {
            color: $font-primary;
        }
        strong.m-t {
            display: block;
            margin-bottom: -4px;
        }
        ul {
            margin-bottom: 0;
            padding-left: 0;
            li {
                list-style-type: none;
            }
        }
    }
}