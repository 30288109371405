footer {
    .container {
        padding-top: 25px;
        padding-bottom: 25px;
        background-color: $bg-footer;
        color: #ffffff;
        .row {
            .widget {
                margin-bottom: 25px;
                h3, p, ul li, a, a:hover {
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 1.167;
                    color: #ffffff;
                }
                h3 {
                    margin-top: 0;
                    margin-bottom: 12px;
                }
                ul {
                    padding-left: 0;
                    li {
                        margin-bottom: 12px;
                        list-style-type: none;
                    }
                }
            }
        }
    }
}