#startseite {
    .bild {
        img {
            display: block;
            max-width: 100%;
            height: auto;
        }
    }
}


@media only screen and (min-width: 768px) {
    #blank {
        padding-left: 80px;
    }
}


.team-sc {
    .teamm {
        padding-bottom: 15px;
        img  {
            max-width: 100%;
            height: auto;
        }
        .name, .pos {
            font-size: 12px;
            line-height: 1.25;
            text-align: center;
        }
        @media only screen and (min-width: 767px) {
            .name, .pos {
                margin-left: -15px;
            }
        }
        .name {
            padding-top: 8px;
        }
        .pos {
            padding-top: 3px;
            font-style: italic;
        }
    }
}
.content-main {
    margin-bottom: 30px;
    ul {
        padding-left: 18px;
        li {
            margin-bottom: 5px;
        }
    }
}
.custom-content {
    margin-bottom: 30px;
    .akkordeon {
        margin-bottom: 30px;
        .panel {
            .panel-heading {
                padding-left: 0;
                h3.panel-title {
                    .fa {
                        color: $brand-orange;
                        font-weight: 700;
                        width: 12px;
                        margin-right: 10px;
                    }
                    .fa.fa-circle-thin {
                        font-size: 12px;
                        margin-left: -3px;
                        margin-right: 13px;
                    }
                    a {
                        display: block;
                        .fa.fa-angle-right {
                            display: none;
                        }
                        .fa.fa-angle-down {
                            margin-left: -3px;
                            margin-right: 13px;
                        }
                    }
                    a.collapsed {
                        .fa.fa-angle-right {
                            display: inline-block;
                        }
                        .fa.fa-angle-down {
                            display: none;
                        }
                    }
                    a:hover, a:active, a:visited, a:link {
                        text-decoration: none;
                    }
                }
            }
            .panel-collapse {
                .panel-body {
                    padding-left: 23px;
                }
            }
        }
    }
}
#kontakt {
    .kontakt-form {
        .form-thanks {
            border: 1px solid $green-primary;
            color: $green-primary;
            background-color: $green-light;
            padding: 5px 10px;
            text-align: center;
            border-radius: 5px;
            font-weight: bold;
        }
    }
}
.tq-gallery {
    .gallery-thumbnail {
        display: inline-block;
        margin: 0 20px 20px 0;
    }
}
@media only screen and (min-width: 768px) {
    .tq-gallery {
        margin-right: -50px;
    }
}

@media only screen and  (max-width: 767px){
    body.page-id-121 {
        main {
            .team-sc {
                .teamm {
                    img {
                        display: block;
                        margin: 0 auto;
                    }
                    .pos {
                        min-height: 35px;
                    }
                }
            }
        }
    }
}
